import { Box, Button, Grid, IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import Heading from '../../components/Heading';
import {
    CloseOutlined,
    LockOutlined,
    LockOpenOutlined,
    LockResetOutlined,
    LoginOutlined,
    SaveOutlined,
    Info,
} from '@mui/icons-material';
import { Permission, User, USER_GROUP_ADMIN, USER_GROUP_ADVERTISER, USER_GROUP_AGENCY } from '../../types/User';
import Utils from '../../components/Utils';
import { Dealer } from '../../types/Dealer';
import ConfirmDialog, { ConfirmDialogProps } from '../../components/ConfirmDialog';
import MultiAdvertiserSelector from '../../components/MultiAdvertiserSelector';
import AgencySelector from '../../components/AgencySelector';
import { UserContext } from '../../App';
import PermissionSelectComponent from './PermissionSelectComponent';
import { InfoMessageContext } from '../../components/GlobalInfoMessage';
import { CDTextField } from '../../components/CDTextField';

export default function UserEdit() {
    const params = useParams();
    const defaultError = <div></div>;
    const passwordMinLength = 8;
    const id = params.id && params.id !== 'new' ? +params.id : null;
    const [user, setUser] = useState<User>(new User());
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [dealerIds, setDealerIds] = useState<number[]>([]);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);
    const { userContext, setUserContext, refreshUserContext } = useContext(UserContext);
    const { showMessage } = useContext(InfoMessageContext);
    const [hasPassError, setHasPassError] = useState(false);
    const [passErrorText, setPassErrorText] = useState(defaultError);

    function handlePermissionChange(permissions: Permission[]) {
        setUser({ ...user, permissions: permissions });
    }

    function userTypeChanged(event: ChangeEvent<HTMLInputElement>) {
        setUser({
            ...user,
            permissions: [],
            [event.target.name]: event.target.value,
        });
    }

    function validatePassword(value: string) {
        let hasCapitalLetter = false,
            hasNumber = false,
            hasSpecialChar = false,
            hasLength = false;
        const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        hasCapitalLetter = value !== value.toLowerCase();
        hasNumber = value.match(/\d+/) !== null;
        hasSpecialChar = specialChar.test(value);
        hasLength = value.length >= passwordMinLength;

        const isValid = hasCapitalLetter && hasNumber && hasSpecialChar && hasLength;
        setHasPassError(!isValid);
        const errors = getErrorText(hasCapitalLetter, hasNumber, hasSpecialChar, hasLength);
        if (!isValid) {
            setPassErrorText(<div>{errors}</div>);
        } else {
            setPassErrorText(defaultError);
        }
    }

    function getErrorText(hasCapitalLetter: boolean, hasNumber: boolean, hasSpecialChar: boolean, hasLength: boolean) {
        const errorList = [];
        if (!hasCapitalLetter) {
            errorList.push(
                <span>
                    At least 1 Capital Letter <br />
                </span>
            );
        }
        if (!hasNumber) {
            errorList.push(
                <span>
                    At least 1 Number <br />{' '}
                </span>
            );
        }

        if (!hasSpecialChar) {
            errorList.push(
                <span>
                    At lease 1 special character <br />
                </span>
            );
        }

        if (!hasLength) {
            errorList.push(<span>At least 8 characters long</span>);
        }
        return errorList;
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name == 'temporaryPassword') {
            validatePassword(event.target.value);
        }

        let newUser = { ...user, [event.target.name]: event.target.value };
        setUser(newUser);
    }

    function agencySelected(agencyId: number) {
        setUser({ ...user, agencyId: agencyId });
    }

    function handleDealerChange(value: number[]) {
        setDealerIds(value);
    }

    function toggleUserEnabled() {
        user.enabled = !user.enabled;
        setUser(user);
        ApiService.toggleUserEnabled(user)
            .then(() => {
                showMessage('success', user.enabled ? 'User has been enabled.' : 'User has been disabled.');
            })
            .catch(() => {});
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        user.dealers = [];
        dealerIds.forEach((id) => {
            let dealer = dealers.find((dealer) => dealer.id === id);
            if (dealer) {
                user.dealers.push(dealer);
            }
        });
        if (!user.group) {
            showMessage('error', 'User Type is required.');
            return;
        }
        if (user.group === USER_GROUP_ADVERTISER) {
            if (user.dealers.length === 0) {
                showMessage('error', 'At least one Advertiser must be assigned.');
                return;
            }
        }
        if (user.group === USER_GROUP_AGENCY) {
            if (!user.agencyId) {
                return;
            }
        } else {
            user.agencyId = null;
        }

        ApiService.updateUser(user)
            .then((response) => {
                showMessage('success', 'User has been updated.');
                setUser(response.data);
                if (userContext.username === user.userName) {
                    refreshUserContext();
                }
            })
            .catch(() => {});
    }

    function showResetPasswordConfirm() {
        setConfirmDialogProps({
            title: 'Set Temporary Password',
            text:
                'This will send ' +
                user.name +
                ' an Email containing their user name and temporary password. The password must be changed after logging in.   Temporary Passwords expire in 7 days.',
            onOk: resetPassword,
            onCancel: hideConfirm,
            okText: 'Reset Password',
            okIcon: <LockResetOutlined />,
        });
    }

    function hideConfirm() {
        setConfirmDialogProps(null);
    }

    function resetPassword() {
        hideConfirm();
        let tempPassword: HTMLInputElement = document.getElementById('tempPassword') as HTMLInputElement;
        user.temporaryPassword = tempPassword.value;
        ApiService.resetUserPassword(user)
            .then(() => {
                showMessage('success', 'Password reset request has been sent.');
            })
            .catch((error) => {
                showMessage('error', error.response.data);
            });
    }

    useEffect(() => {
        if (id) {
            ApiService.getUserEdit(id).then((response) => {
                let user: User = response.data.user;
                setUser(user);
                setDealerIds((dealerIds) => {
                    const updatedDealerIds = [...dealerIds];
                    user.dealers.forEach((dealer) => {
                        updatedDealerIds.push(dealer.id as number);
                    });
                    return updatedDealerIds;
                });
                setDealers(response.data.dealers);
            });
        } else {
            ApiService.getDealers()
                .then((response) => {
                    setDealers(response.data);
                })
                .catch();
        }
    }, [id]);

    return (
        <>
            <Heading>{Utils.includeSeparator('User', ':', user.name)}</Heading>
            <Box sx={{ minHeight: '100%', p: 3 }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <CDTextField
                                fullWidth
                                label="Username"
                                name="userName"
                                autoFocus={!id}
                                required={id === null}
                                disabled={id !== null}
                                onChange={handleChange}
                                value={user ? user.userName : ''}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {id == null && (
                                <CDTextField
                                    fullWidth
                                    label="Temporary Password"
                                    name="temporaryPassword"
                                    type="password"
                                    required={id === null}
                                    disabled={id !== null}
                                    onChange={handleChange}
                                    error={hasPassError}
                                    helperText={passErrorText}
                                    value={user.temporaryPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip
                                                    title={
                                                        <div>
                                                            At least 1 Capital Letter <br /> At least 1 Number <br /> At
                                                            lease 1 special character <br /> At least 8 characters long
                                                        </div>
                                                    }
                                                >
                                                    <IconButton aria-label="temporary password help">
                                                        <Info />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            {id != null && (
                                <CDTextField
                                    fullWidth
                                    label="QuickSight Username"
                                    name="quickSightUserName"
                                    disabled={true}
                                    InputLabelProps={{ shrink: true }}
                                    value={user.quickSightUserName}
                                />
                            )}
                        </Grid>
                        <Grid item md={6}>
                            <CDTextField
                                fullWidth
                                label="Name"
                                name="name"
                                onChange={handleChange}
                                value={user ? user.name : ''}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <CDTextField
                                fullWidth
                                type="email"
                                label="Email address"
                                name="emailAddress"
                                onChange={handleChange}
                                required
                                value={user.emailAddress}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <CDTextField
                                fullWidth
                                label="User Type"
                                name="group"
                                select
                                error={!user.group}
                                value={user.group}
                                onChange={userTypeChanged}
                                required={true}
                                SelectProps={{ native: false }}
                            >
                                <MenuItem key={USER_GROUP_ADMIN} value={USER_GROUP_ADMIN}>
                                    Admin
                                </MenuItem>
                                <MenuItem key={USER_GROUP_AGENCY} value={USER_GROUP_AGENCY}>
                                    Agency
                                </MenuItem>
                                <MenuItem key={USER_GROUP_ADVERTISER} value={USER_GROUP_ADVERTISER}>
                                    Advertiser
                                </MenuItem>
                            </CDTextField>
                        </Grid>

                        <Grid item md={6}>
                            <CDTextField
                                fullWidth
                                size="small"
                                label="Cognito Status"
                                name="status"
                                disabled
                                value={Utils.convertCase(user.status)}
                            />
                        </Grid>

                        {user.group === USER_GROUP_ADVERTISER && (
                            <>
                                <Grid item md={6}>
                                    {dealers.length > 0 && (
                                        <MultiAdvertiserSelector
                                            dealers={dealers}
                                            dealerIds={dealerIds}
                                            onDealerChange={handleDealerChange}
                                            label="Advertisers"
                                            required={true}
                                        />
                                    )}
                                </Grid>
                                <Grid item md={6} />
                            </>
                        )}

                        {user.group === USER_GROUP_AGENCY && (
                            <>
                                <Grid item md={6}>
                                    <AgencySelector
                                        agencyId={user.agencyId}
                                        agencySelected={agencySelected}
                                        userMessage={true}
                                    />
                                </Grid>
                                <Grid item md={6} />
                            </>
                        )}

                        {user.group === USER_GROUP_ADMIN && (
                            <>
                                <Grid item md={6}>
                                    <PermissionSelectComponent
                                        userType={user.group}
                                        permissions={user.permissions}
                                        onChange={handlePermissionChange}
                                    />
                                </Grid>

                                <Grid item md={6}></Grid>
                            </>
                        )}
                    </Grid>

                    <Grid item md={12} style={{ marginTop: '30px' }}>
                        <Stack direction="row" spacing={2}>
                            <Button
                                disabled={user.readOnly}
                                color="primary"
                                type="submit"
                                variant="contained"
                                startIcon={<SaveOutlined />}
                            >
                                Save
                            </Button>
                            <Link to="/users">
                                <Button variant="outlined" color="error" startIcon={<CloseOutlined />}>
                                    Cancel
                                </Button>
                            </Link>
                            <div style={{ flexGrow: 1 }} />
                            {user.id && user.group !== USER_GROUP_ADMIN && (
                                <Tooltip title="Login as this user">
                                    <Button
                                        color="secondary"
                                        onClick={() => {
                                            setUserContext(
                                                user.userName,
                                                user.name,
                                                user.group,
                                                user.agencyId,
                                                user.organization,
                                                user.agency
                                            );
                                        }}
                                        variant="contained"
                                        startIcon={<LoginOutlined />}
                                    >
                                        Impersonate
                                    </Button>
                                </Tooltip>
                            )}
                            {user.id && (
                                <Button
                                    color="primary"
                                    disabled={user.readOnly}
                                    onClick={showResetPasswordConfirm}
                                    variant="outlined"
                                    startIcon={<LockResetOutlined />}
                                >
                                    Reset Password
                                </Button>
                            )}

                            {user.id && user.enabled && (
                                <Button
                                    color="error"
                                    disabled={user.readOnly}
                                    onClick={toggleUserEnabled}
                                    variant="outlined"
                                    startIcon={<LockOutlined />}
                                >
                                    Disable
                                </Button>
                            )}
                            {user.id && !user.enabled && (
                                <Button
                                    color="primary"
                                    disabled={user.readOnly}
                                    onClick={toggleUserEnabled}
                                    variant="outlined"
                                    startIcon={<LockOpenOutlined />}
                                >
                                    Enable
                                </Button>
                            )}
                        </Stack>
                    </Grid>
                </form>
            </Box>
            {confirmDialogProps && (
                <>
                    <ConfirmDialog
                        title={confirmDialogProps.title}
                        text={confirmDialogProps.text}
                        okText={confirmDialogProps.okText}
                        okIcon={confirmDialogProps.okIcon}
                        onCancel={confirmDialogProps.onCancel}
                        onOk={confirmDialogProps.onOk}
                    >
                        <Box sx={{ minHeight: '100%', p: 3 }}>
                            <TextField
                                id="tempPassword"
                                variant="filled"
                                fullWidth
                                size="small"
                                label="Temporary Password"
                                name="temporaryPassword"
                                type="password"
                                helperText="Minimum length is 8.  Must contain 1 number, 1 Uppercase, 1 lowercase, 1 special character."
                                required={true}
                            />
                        </Box>
                    </ConfirmDialog>
                </>
            )}
        </>
    );
}
