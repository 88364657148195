import { Alert, AppBar, Box, Chip, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useScrollTrigger } from '@mui/material';
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { ScheduleSendOutlined as ScheduleSendOutlinedIcon } from '@mui/icons-material';
import moment from 'moment-timezone';

import { Dealer } from '../../types/Dealer';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { generateMonthsByDateRange } from '../../components/CDMonthSelect';
import { SectionHeading } from '../../components/SectionHeading';
import CDMonthSelect from '../../components/CDMonthSelect';
import TabPanel from '../../components/TabPanel';
import { AdvertiserContext } from '../../AdvertiserContext';
import { UserContext } from '../../App';
import { PrintContext } from '../../hooks/usePrint';
import usePrint from '../../hooks/usePrint';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

import { TABS as DATA_TABS } from './PolkMatchbackDataTabs';
import { AttributionPageContext, TABS } from '../../hooks/useAttributionPage';
import useAttributionPage from '../../hooks/useAttributionPage';
import AdvertiserMatchbackSetup from './AdvertiserMatchbackSetup';
import AdvertiserMatchbackTab from './AdvertiserMatchbackTab';
import AttributionExportMenuButton from './AttributionExportMenuButton';
import PolkMatchbackSetup from './PolkMatchbackSetup';
import PolkMatchbackTab from './PolkMatchbackTab';
import AttributionScheduleEmailDialog from './AttributionScheduleEmailDialog';

export default function AttributionPage() {
    const scrollTrigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 32,
    });

    const [dealer, setDealer] = useState<Dealer | null>(null);

    const attributionPage = useAttributionPage({
        dealer: dealer,
    });
    const {
        tab,
        setTab,
        attributionDate,
        setAttributionDate,
        setAdvertiserSalesUpdateDate,
        polkDealerDealers,
        fetchPolkDealerDealers,
    } = attributionPage;

    const [salesDataUploads, setSalesDataUploads] = useState<SalesDataUpload[]>([]);
    const [scheduleEmailDialogOpen, setScheduleEmailDialogOpen] = useState<boolean>(false);

    const print = usePrint();
    const { advertiserContext } = useContext(AdvertiserContext);
    const { userContext } = useContext(UserContext);

    const isPolkMatchbackSetup = useMemo(() => {
        return polkDealerDealers.length > 0;
    }, [polkDealerDealers]);

    const isAdvertiserMatchbackSetup = useMemo(() => {
        return salesDataUploads.length > 0;
    }, [salesDataUploads]);

    function selectTab(_event: SyntheticEvent, value: any) {
        switch (value) {
            case TABS.ADVERTISER_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.CAMPAIGN_PERFORMANCE as any as string);
                localStorage.setItem('attribution.advertiserView', 'chart');
                break;

            case TABS.POLK_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);
                localStorage.setItem('attribution.polkView', 'chart');
                break;
        }

        setTab(value);
    }

    function handleAttributionDate(_attributionDate: Date) {
        setAttributionDate(_attributionDate);
    }

    function generatePreviousMonths() {
        // Remove current month
        return generateMonthsByDateRange(moment('2024-01-01').toDate()).slice(1);
    }

    useEffect(() => {
        const previousMonths = generatePreviousMonths();

        if (previousMonths && previousMonths.length) {
            if (previousMonths.filter((pm) => pm.date === attributionDate).length <= 0) {
                setAttributionDate(previousMonths[0].date);
            }
        }

        localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (advertiserContext?.dealer?.id) {
            setDealer(advertiserContext.dealer);
        } else if (userContext.isAdvertiser()) {
            ApiService.getAssignedDealers()
                .then((response) => {
                    if (response.data.length === 1) {
                        setDealer(response.data[0]);
                    } else {
                        setDealer(null);
                    }
                })
                .catch();
        } else {
            setDealer(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertiserContext.dealer, userContext]);

    useEffect(() => {
        fetchPolkDealerDealers();
    }, [fetchPolkDealerDealers]);

    useEffect(() => {
        if (dealer?.id) {
            ApiService.getSalesDataUploadsByDealer(null, dealer.id).then((response) => {
                setSalesDataUploads(response.data);
            });
        } else {
            setSalesDataUploads([]);
        }
    }, [dealer]);

    useEffect(() => {
        if (salesDataUploads && salesDataUploads.length) {
            setAdvertiserSalesUpdateDate(Utils.getDate(salesDataUploads[0].dateUploaded));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesDataUploads]);

    return (
        <PrintContext.Provider value={print}>
            <AttributionPageContext.Provider value={attributionPage}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <AppBar
                        position="sticky"
                        color="transparent"
                        sx={{
                            backgroundColor: (theme) => theme.palette.common.white,
                            top: '64px',
                            pt: scrollTrigger ? 2 : 0,
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                            <div>
                                <SectionHeading
                                    subheading={'Uncover the impact of your marketing efforts.'}
                                    divider={false}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h6">Attribution</Typography>
                                        <Chip label="Beta" size="small" />
                                    </Stack>
                                </SectionHeading>
                            </div>

                            {dealer && dealer.id && (
                                <Stack direction="row" spacing={1}>
                                    <Tooltip
                                        placement="top"
                                        title="Previous Month data will be available within 7 days of the start of the current month"
                                    >
                                        <div>
                                            <CDMonthSelect
                                                required={false}
                                                value={attributionDate}
                                                months={generatePreviousMonths()}
                                                change={(selectedMonth: any) => {
                                                    if (selectedMonth) {
                                                        handleAttributionDate(selectedMonth.date);
                                                    }
                                                }}
                                                variant="outlined"
                                                label="Month"
                                                InputProps={{
                                                    startAdornment: <></>,
                                                }}
                                                sx={{ width: 180 }}
                                            />
                                        </div>
                                    </Tooltip>

                                    {false && userContext.isAdmin() && (
                                        <>
                                            <IconButton
                                                disabled={isPolkMatchbackSetup === false}
                                                onClick={() => setScheduleEmailDialogOpen(true)}
                                            >
                                                <ScheduleSendOutlinedIcon fontSize="small" />
                                            </IconButton>

                                            <AttributionScheduleEmailDialog
                                                open={scheduleEmailDialogOpen}
                                                onClose={() => setScheduleEmailDialogOpen(false)}
                                            />
                                        </>
                                    )}

                                    <AttributionExportMenuButton disabled={isPolkMatchbackSetup === false} />
                                </Stack>
                            )}
                        </Stack>

                        {(!dealer || !dealer?.id) && (
                            <>
                                <Alert severity="info" sx={{ my: 2 }}>
                                    Select an Advertiser to view the available Attribution data.
                                </Alert>
                            </>
                        )}

                        {dealer && dealer.id && (
                            <>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                        mt: 2,
                                    }}
                                >
                                    <Tabs value={tab} onChange={selectTab}>
                                        <Tab
                                            value={TABS.POLK_REPORTED_SALES}
                                            label={
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <span>Polk Reported Sales</span>
                                                    <Chip
                                                        variant="outlined"
                                                        label="New"
                                                        size="small"
                                                        color="success"
                                                        sx={{ backgroundColor: '#EDF7ED' }}
                                                    />
                                                </Stack>
                                            }
                                        />
                                        <Tab
                                            value={TABS.ADVERTISER_REPORTED_SALES}
                                            label={
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <span>Advertiser Reported Sales</span>
                                                    <Chip
                                                        variant="outlined"
                                                        label="New"
                                                        size="small"
                                                        color="success"
                                                        sx={{ backgroundColor: '#EDF7ED' }}
                                                    />
                                                </Stack>
                                            }
                                        />
                                    </Tabs>
                                </Box>
                            </>
                        )}
                    </AppBar>

                    {dealer && dealer.id && (
                        <>
                            <TabPanel
                                index={TABS.POLK_REPORTED_SALES}
                                value={tab}
                                BoxProps={{
                                    sx: { p: 0 },
                                }}
                            >
                                {isPolkMatchbackSetup === true && <PolkMatchbackTab />}
                                {isPolkMatchbackSetup === false && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <PolkMatchbackSetup dealer={dealer} />
                                    </Stack>
                                )}
                            </TabPanel>

                            <TabPanel
                                index={TABS.ADVERTISER_REPORTED_SALES}
                                value={tab}
                                BoxProps={{
                                    sx: { p: 0 },
                                }}
                            >
                                {isAdvertiserMatchbackSetup === true && <AdvertiserMatchbackTab />}
                                {isAdvertiserMatchbackSetup === false && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <AdvertiserMatchbackSetup dealer={dealer} />
                                    </Stack>
                                )}
                            </TabPanel>
                        </>
                    )}
                </Box>
            </AttributionPageContext.Provider>
        </PrintContext.Provider>
    );
}
