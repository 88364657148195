import { isValidElement, useMemo } from 'react';
import { deepmerge } from '@mui/utils';
import { Box, Card, CardContent, CardHeader, Stack, Tooltip, Typography } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import theme from '../../theme';
import Utils from '../../components/Utils';

export default function AttributionGroupedBarChartCard(props: {
    label: string | null | undefined | React.ReactElement;
    tooltip?: string;
    chartOptions?: ApexOptions;
    chartSeries?: ApexAxisChartSeries | number[];
    noDataOverlay?: React.ReactElement;
}) {
    const { label, tooltip, chartOptions, chartSeries, noDataOverlay } = props;

    const defaultChartOptions: ApexOptions = {
        chart: {
            type: 'bar',
            height: 300,
            // offsetX: 0,
            toolbar: {
                show: false,
            },
        },
        colors: ['#0e59e7', '#6771dc', '#8067dc', '#b967db'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                borderRadiusApplication: 'end',
                horizontal: true,
                dataLabels: {
                    position: 'top',
                    // total: {
                    //     enabled: true,
                    //     offsetX: 50,
                    //     offsetY: 50,
                    // },
                },
                // columnWidth: '50%',
                barHeight: '80%',
            },
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            offsetX: 32,
            // offsetY: 5,
            style: {
                colors: [theme.palette.text.secondary],
                fontFamily: theme.typography.fontFamily,
                fontSize: '12px',
                fontWeight: 400,
            },
            formatter: (value, opts) => {
                return Utils.formatNumber(value as number) as string;
            },
        },
        grid: {
            show: false,
            padding: {
                bottom: 24,
            },
        },
        legend: {
            // offsetY: 6,
            // offsetY: 12,
            itemMargin: {
                horizontal: 16,
                vertical: 0,
            },
            markers: {
                offsetX: -4,
            },
        },
        stroke: {
            colors: ['transparent'],
            width: 4,
        },
        tooltip: {
            y: {
                formatter: (value) => {
                    return Utils.formatNumber(value as any) as string;
                },
            },
        },
        xaxis: {
            tooltip: {
                enabled: false,
            },
            labels: {
                formatter: (value) => {
                    return Utils.formatNumber(value as any) as string;
                },
                style: {
                    colors: [theme.palette.text.secondary],
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            tooltip: {
                enabled: false,
            },
            labels: {
                maxWidth: 240,
                style: {
                    colors: [theme.palette.text.secondary],
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '12px',
                    fontWeight: 400,
                },
            },
        },
    };

    const hasData: boolean | undefined = useMemo(() => {
        return chartSeries && chartSeries.length > 0 ? true : false;
    }, [chartSeries]);

    const hasNoDataOverlay: boolean | undefined = useMemo(() => {
        return noDataOverlay !== null && noDataOverlay !== undefined;
    }, [noDataOverlay]);

    const boxHeight: number = 560;

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        {isValidElement(label) ? (
                            label
                        ) : (
                            <Typography variant="body1" color="text.secondary">
                                {label}
                            </Typography>
                        )}

                        {tooltip ? (
                            <Tooltip title={tooltip}>
                                <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Stack>
                }
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            />
            <CardContent>
                <Box
                    sx={{
                        display: hasData === true ? 'block' : 'none',
                    }}
                >
                    <ReactApexChart
                        type="bar"
                        options={deepmerge(defaultChartOptions, chartOptions)}
                        series={chartSeries}
                        height={boxHeight}
                        // width={500}
                    />
                </Box>

                {hasData === false && (
                    <>
                        {hasNoDataOverlay === true && <>{noDataOverlay}</>}

                        {hasNoDataOverlay === false && (
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: boxHeight + 2 }}
                            >
                                <Typography variant="body2">No data available</Typography>
                            </Stack>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
}
