import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import {
    Box,
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';

import { Performance } from '../../types/CampaignPerformance';
import { Table as BaseTable } from '../../components/Table';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { calculatePerformanceValue } from '../../hooks/useCampaignPerformance';

const Table = styled(BaseTable)(({ theme }) => ({
    '&': {
        tableLayout: 'fixed',
    },
    [`& .${tableRowClasses.root} .${tableCellClasses.root}:nth-of-type(1)`]: {
        textAlign: 'left',
        width: '15vw',
    },
    [`& .${tableRowClasses.root} .${tableCellClasses.root}`]: {
        textAlign: 'right',
        width: '10vw',
    },
}));

const TablePrimaryCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

type TableCellTextProps = {
    children: React.ReactElement | string;
    tooltip: string;
};

function TableCellText({ children, tooltip }: TableCellTextProps) {
    return (
        <Row gap={0.5} alignItems="center" justifyContent="flex-end">
            {children}

            <Printable show={false}>
                <Tooltip title={tooltip}>
                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                </Tooltip>
            </Printable>
        </Row>
    );
}

export type PerformancesTableProps = { performances: Performance[] };

export function DisplayPerformancesTable({ performances }: PerformancesTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export function VideoPerformancesTable({ performances }: PerformancesTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Estimated Cost per Completed View">eCPCV</TableCellText>
                    </TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>
                            {Utils.formatValue(calculatePerformanceValue(performance, 'eCPCV'), 'dollar')}
                        </TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export function AudioPerformancesTable({ performances }: PerformancesTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Spend</TableCell>
                    <TableCell>Avg. CPM</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {performances.map((performance: Performance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{performance.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(performance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(performance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(performance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default function AdvertiserMatchbackCampaignTable() {
    const { getPerformances, getPerformanceMediaTypes } = useAttributionPageContext();

    const mediaTypes: string[] = getPerformanceMediaTypes();

    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            {mediaTypes &&
                mediaTypes.length > 0 &&
                mediaTypes.map((mediaType) => (
                    <Box key={mediaType}>
                        <Row
                            sx={{
                                backgroundColor: (theme) => theme.palette.action.hover,
                                padding: (theme) => theme.spacing(2),
                            }}
                        >
                            <Typography variant="subtitle2" color="text.primary">
                                {mediaType}
                            </Typography>
                        </Row>
                        {mediaType === 'Display' && (
                            <DisplayPerformancesTable performances={getPerformances(mediaType)} />
                        )}
                        {mediaType === 'Video' && <VideoPerformancesTable performances={getPerformances(mediaType)} />}
                        {mediaType === 'Audio' && <AudioPerformancesTable performances={getPerformances(mediaType)} />}
                    </Box>
                ))}
        </TableContainer>
    );
}
