import { useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Dealer } from '../../types/Dealer';
import { AttributionStatusSet } from '../../types/Polk';
import { PolkWorkflowSetDataGrid } from './PolkAttributionStatusDataGrid';

export type PolkWorkflowSetDataGridDialogProps = {
    open: boolean;
    onClose: () => void;
    dealer: Dealer;
    attributionStatusSet: AttributionStatusSet;
};

export default function PolkWorkflowSetDataGridDialog(props: PolkWorkflowSetDataGridDialogProps) {
    const [open, setOpen] = useState<boolean>(props.open);

    const handleClose = () => {
        setOpen(false);

        props.onClose();
    };

    return (
        <>
            <Dialog fullWidth={true} maxWidth="xl" onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>View Previous Runs</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ pt: 3, pb: 5 }}>
                    <Box
                        sx={{
                            height: '480px',
                            width: '100%',
                            flexGrow: 1,
                        }}
                    >
                        <PolkWorkflowSetDataGrid
                            dealer={props.dealer}
                            rows={props.attributionStatusSet.previousSalesWorkflowSets}
                        />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
