import { MouseEvent, useContext, useState } from 'react';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { AccountCircle, ArrowDropDown, KeyboardReturn, LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { CDToolBarProps } from './CDToolbar';
import theme from '../theme';
import { navItemBackgroundHover } from '../theme';

export default function UserMenu(props: { toolbarProps: CDToolBarProps }) {
    const { logout } = props.toolbarProps;
    const { userContext, setUserContext } = useContext(UserContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event: MouseEvent<HTMLElement>) => {
        if (userContext.adminContext) {
            setUserContext(null, undefined, undefined, null, undefined);
            navigate('/users');
        } else {
            logout();
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="user-menu-toggle-button"
                aria-controls={open ? 'user-menu-toggle-button' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                sx={{
                    color: userContext.adminContext ? theme.palette.warning.main : theme.palette.primary.contrastText,
                    fontSize: 15,
                    borderRadius: '8px',
                    paddingX: 2,
                    paddingY: 1,
                    backgroundColor: open ? navItemBackgroundHover : 'inherit',

                    '&:hover': {
                        backgroundColor: navItemBackgroundHover,
                    },
                }}
                onClick={handleClick}
            >
                <Stack direction="row" spacing={1} color="inherit">
                    <AccountCircle />

                    <Typography>{userContext ? userContext.name : ''}</Typography>

                    <ArrowDropDown />
                </Stack>
            </IconButton>
            <Menu
                id="user-menu"
                MenuListProps={{
                    'aria-labelledby': 'user-menu-toggle-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '220px',
                    },
                }}
            >
                <MenuItem
                    key="logout"
                    onClick={handleLogout}
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                    }}
                >
                    <Stack direction="row" spacing={2}>
                        {userContext.adminContext ? (
                            <>
                                <KeyboardReturn />
                                <Typography>Return to Admin View</Typography>
                            </>
                        ) : (
                            <>
                                <LogoutOutlined />
                                <Typography>Logout</Typography>
                            </>
                        )}
                    </Stack>
                </MenuItem>
            </Menu>
        </div>
    );
}
