import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment, Typography } from '@mui/material';
import { HelpOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';
import StylableTooltip from './StylableTooltip';
import theme from '../theme';

type CDTextFieldProps = TextFieldProps & {
    shrinkLabel?: boolean;
    help?: string;
};

const HtmlTooltip = styled(StylableTooltip)(() => ({
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: theme.palette.text.primary,
}));

export function CDTextField(props: CDTextFieldProps) {
    const { shrinkLabel, help, InputProps, ...textFieldProps } = props;

    let helpAdornment = <></>;
    if (props.help) {
        helpAdornment = (
            <InputAdornment position="end" sx={{ position: 'absolute', right: '30px' }}>
                <HtmlTooltip
                    disableInteractive
                    title={
                        <>
                            <Typography color="inherit">{props.label}</Typography>
                            {props.help}
                        </>
                    }
                >
                    <HelpOutlined color="primary" />
                </HtmlTooltip>
            </InputAdornment>
        );
    }

    return (
        <TextField
            sx={{
                '& .Mui-disabled > .MuiOutlinedInput-notchedOutline ': {
                    border: 'none',
                },
            }}
            autoComplete="off"
            size="small"
            variant="outlined"
            InputProps={{
                endAdornment: helpAdornment,
                ...InputProps,
            }}
            InputLabelProps={{
                shrink: shrinkLabel === undefined ? true : shrinkLabel,
                ...textFieldProps.InputLabelProps,
            }}
            {...textFieldProps}
        />
    );
}
