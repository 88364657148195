import JoditEditor from 'jodit-react';
import { useEffect, useRef, useMemo } from 'react';
import { alpha, Box, styled } from '@mui/material';

import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/theme-idle_fingers';
import 'ace-builds/src-min-noconflict/mode-html';

const customJoditTheme = 'cd_dark';

const JoditEditorContainer = styled(Box)(({ theme }) => ({
    [`.jodit-dialog_theme_${customJoditTheme}, .jodit_theme_${customJoditTheme}`]:
        {
            '--color-border': theme.palette.divider,
            '--dark_background_color': theme.palette.background.paperElevation0,
            '--dark_background_ligher': theme.palette.action.hover,
            '--dark_border_color': theme.palette.action.disabledBackground,
            '--dark_icon_color': theme.palette.text.secondary,
            '--dark_text_color': theme.palette.common.white,
            '--dark_link_color': theme.palette.primary.main,
            '--dark_text_color_opacity80': alpha(
                theme.palette.text.primary,
                0.8
            ),
            '--dark_toolbar_color': theme.palette.background.paper,
            '--dark_toolbar_seperator_color1':
                theme.palette.action.disabledBackground,
            '--dark_toolbar_seperator_color2':
                theme.palette.action.disabledBackground,
            '--dark_toolbar_seperator_color3':
                theme.palette.action.disabledBackground,

            '--button-icon-size': '14px',
            '--margin-v': '2px',
            '--button-df-size': 'calc((var(--button-icon-size) - 4px) * 2)',

            '--button-size':
                'calc(var(--button-icon-size) + var(--button-df-size) + var(--margin-v) * 2)',

            '.jodit-toolbar-collection, .jodit-toolbar-editor-collection': {
                '&_mode_horizontal': {
                    backgroundImage:
                        'repeating-linear-gradient(transparent 0, transparent calc(var(--button-size) - 1px), var(--color-border) var(--button-size))',

                    '&:after': {
                        'background-color': 'var(--color-border)',
                    },
                },
            },

            '.jodit-toolbar': {
                '&__box': {
                    '&:not(:empty)': {
                        borderColor: 'var(--color-border)',

                        '.jodit-toolbar-editor-collection:after': {
                            backgroundColor: 'var(--color-border)',
                        },
                    },
                },
            },

            '.jodit-ui-group_separated_true:not(:last-child):not(.jodit-ui-group_before-spacer_true):after':
                {
                    borderRightColor: 'var(--color-border)',
                },

            '&.jodit-container': {
                borderColor: 'var(--color-border)',
                backgroundColor: 'var(--dark_background_color)',
            },

            '&.jodit-container.jodit_disabled': {
                backgroundColor: 'var(--dark_background_color)',
            },

            '&.jodit-container:not(.jodit_inline) .jodit-workplace': {
                borderColor: 'var(--dark_background_color)',
                '& a:link': {
                    color: 'var(--dark_link_color)',
                },
            },

            '.jodit-popup': {
                '&__content': {
                    background: 'var(--dark_background_ligher)',
                },
            },

            '.jodit-ui-button, .jodit-toolbar-button': {
                '&__text': {
                    color: 'var(--dark_text_color)',
                },

                '&, &__button, &__trigger': {
                    '&:hover:not([disabled])': {
                        backgroundColor: 'var(--dark_background_ligher)',
                    },
                },
            },

            '.jodit-workplace + .jodit-status-bar:not(:empty), .jodit-status-bar':
                {
                    borderColor: 'var(--color-border)',
                    backgroundColor: 'var(--dark_toolbar_color)',
                    color: 'var(--dark_text_color)',

                    'a.jodit-status-bar-link': {
                        '&, &:hover, &:visited': {
                            color: 'var(--dark_text_color)',
                        },
                    },

                    '&, .jodit-status-bar__item a, .jodit-status-bar__item span':
                        {
                            color: 'var(--dark_text_color)',
                        },
                },

            '.jodit-toolbar__box:not(:empty)': {
                background: 'var(--dark_toolbar_color)',
            },

            '.jodit__upload-button svg, .jodit-toolbar-button .jodit-toolbar-button__trigger, .jodit-icon':
                {
                    fill: 'var(--dark_icon_color)',
                    stroke: 'var(--dark_icon_color)',
                },

            '.jodit-icon-close': {
                stroke: 'var(--dark_icon_color)',
            },

        '.jodit-wysiwyg_iframe, .jodit-wysiwyg': {
            backgroundColor: 'var(--dark_background_color)',
        },

        '.jodit-form textarea, .jodit-form input[type="url"], .jodit-form input[type="text"]': {
            borderColor: 'var(--dark_toolbar_seperator_color2)',
            backgroundColor: 'var(--dark_toolbar_seperator_color1)',
        },

            '.jodit-form button': {
                backgroundColor: 'var(--dark_toolbar_seperator_color3)',
                color: 'var(--dark_text_color)',
            },

            '.jodit-placeholder': {
                color: 'var(--dark_text_color_opacity80)',
            },

            '.jodit-drag-and-drop__file-box, .jodit_uploadfile_button': {
                color: 'var(--dark_text_color)',

                '&:hover': {
                    backgroundColor: 'var(--dark_toolbar_seperator_color3)',
                },
            },

            '.jodit-add-new-line': {
                '&::before': {
                    borderTopColor: 'var(--dark_toolbar_seperator_color2)',
                },

                span: {
                    borderColor: 'var(--dark_toolbar_seperator_color2)',
                    background: 'var(--dark_toolbar_seperator_color3)',

                    svg: {
                        fill: 'var(--dark_text_color)',
                    },
                },
            },

            '.jodit-resizer > i': {
                borderColor: 'var(--dark_icon_color)',
                background: 'var(--dark_toolbar_seperator_color3)',
            },

            '.jodit-select, .jodit-input': {
                borderColor: 'var(--dark_border_color)',
                backgroundColor: 'var(--dark_background_ligher)',
                color: 'var(--dark_border_color)',
            },

            '&.jodit-dialog': {
                backgroundColor: 'var(--dark_background_color)',

                '.jodit-filebrowser__files.active .jodit-filebrowser__files-item, .jodit-dialog__header':
                    {
                        borderColor: 'var(--dark_border_color)',
                    },

            '.jodit-filebrowser__files.active .jodit-filebrowser__files-item-info': {
                backgroundColor: 'var(--dark_text_color)',
            },
        },
    },
    '.jodit-placeholder': {
        marginTop: '0 !important', // Override margin-top for placeholder
    },
    '.jodit-wysiwyg p': {
        marginTop: '0 !important', // Override margin-top for p elements
    },
}));

export function ReleaseNotesEditor(props: {
    content: string;
    updateContent: (content: string) => void;
}) {
    const { content, updateContent } = props;
    const editor = useRef(null);

    // Note: using JoditEditorContainer because JoditEditor drops the className
    // property on rerender.
    useEffect(() => {}, [content]);

    const config: any = useMemo(
        () => ({
            readonly: false,
            theme: customJoditTheme,
            toolbarButtonSize: 'middle',
            statusbar: false,
            removeButtons: [
                'underline',
                'strikethrough',
                'eraser',
                'superscript',
                'subscript',
                'lineHeight',
                'spellcheck',
                'cut',
                'copy',
                'paste',
                'selectall',
                'copyformat',
                'symbols',
                'print',
                'about',
                'undo',
                'redo',
            ],
            height: 'auto',
        }),

        []
    );
    return (
        <JoditEditorContainer>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                onBlur={(newContent) => {
                    updateContent(newContent);
                }}
                onChange={(newContent) => {
                    updateContent(newContent);
                }}
            />
        </JoditEditorContainer>
    );
}
