import { useMemo } from 'react';

import { PieChartSlice } from '../../types/Chart';
import { Performance } from '../../types/CampaignPerformance';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import AttributionPieChartCard from './AttributionPieChartCard';
import moment from 'moment';

export default function AdvertiserInvestmentPieChartCard() {
    const { performances, attributionDate } = useAttributionPageContext();

    const slices = useMemo((): PieChartSlice[] => {
        let slices: PieChartSlice[] = [];

        const _calculate = (mediaType: string): number => {
            if (performances && performances.length) {
                return performances
                    .filter((p: Performance) => p.mediaType === mediaType)
                    .map((p: Performance) => p.totalCost)
                    .reduce((a, b) => a + b, 0);
            }

            return 0;
        };

        slices.push({
            label: 'Display',
            value: _calculate('Display'),
        });

        slices.push({
            label: 'Video',
            value: _calculate('Video'),
        });

        slices.push({
            label: 'Audio',
            value: _calculate('Audio'),
        });

        slices = slices.filter((s: PieChartSlice) => s.value > 0);

        return slices;
    }, [performances]);

    const slicesTotal = useMemo((): number => {
        if (slices) {
            return slices.map((s) => s.value).reduce((a, b) => a + b, 0);
        }

        return 0;
    }, [slices]);

    const totalSpendMetricLabel: string = useMemo(() => {
        if (attributionDate) {
            return `${moment(attributionDate).format('MMMM')} Investment`;
        }

        return 'Total Investment';
    }, [attributionDate]);

    return (
        <AttributionPieChartCard
            label="Investment by Media Type"
            tooltip="Total ad spend by media type"
            chartOptions={{
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: totalSpendMetricLabel,
                                    formatter: (w) => {
                                        return Utils.formatCurrency(slicesTotal, 2) ?? '';
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        if (value.substring(0, 1) !== '$') {
                                            value = Math.round((parseFloat(value) / slicesTotal) * 100) + '';
                                            return `${value}%`;
                                        }
                                        return value;
                                        // return Utils.formatCurrency(value, 2) ?? '';;
                                    },
                                },
                            },
                        },
                    },
                },
                labels: slices.map((_share) => _share.label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map((_share) => {
                let value: number = Math.round((_share.value / slicesTotal) * 100);
                return value;
            })}
            // chartSeries={slices.map((s: PieChartSlice) => s.value)}
        />
    );
}
