import { useEffect, useMemo, useState } from 'react';
import { FormControl, MenuItem, TextField, Typography } from '@mui/material';

import { Performance } from '../../types/CampaignPerformance';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import AttributionGroupedBarChartCard from './AttributionGroupedBarChartCard';

export default function AdvertiserPerformanceGroupedBarChartCard() {
    const { getPerformances, getPerformanceMediaTypes } = useAttributionPageContext();

    const mediaTypes: string[] = getPerformanceMediaTypes();
    const [mediaType, setMediaType] = useState<string>(() => {
        return Utils.localStorage('attribution.advertiserPerformanceMediaType', 'Display');
    });
    const [performances, setPerformances] = useState<Performance[]>([]);

    const filteredPerfomances = useMemo((): Performance[] => {
        return performances.filter((p: Performance) => p.CPM !== null);
    }, [performances]);

    const seriesDataLabels = useMemo(() => {
        if (filteredPerfomances && filteredPerfomances.length) {
            return filteredPerfomances.map((p: Performance) => p.campaignName);
        }

        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredPerfomances]);

    const seriesData = useMemo(() => {
        if (filteredPerfomances && filteredPerfomances.length) {
            return [
                {
                    name: 'Impressions',
                    data: filteredPerfomances.map((p: Performance) => p?.impressions),
                },
                {
                    name: 'Reach',
                    data: filteredPerfomances.map((p: Performance) => p?.dailyReach),
                },
                {
                    name: 'Clicks',
                    data: filteredPerfomances.map((p: Performance) => p?.clickthroughs),
                },
                {
                    name: 'Conversions',
                    data: filteredPerfomances.map((p: Performance) => p?.offAmazonConversions),
                },
            ];
        }

        return [];
    }, [filteredPerfomances]);

    useEffect(() => {
        localStorage.setItem('attribution.advertiserPerformanceMediaType', mediaType);
    }, [mediaType]);

    useEffect(() => {
        if (mediaTypes && mediaTypes.length) {
            if (!mediaTypes.includes(mediaType)) {
                setMediaType(mediaTypes[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaTypes]);

    useEffect(() => {
        if (mediaType) {
            setPerformances(getPerformances(mediaType));
        } else {
            setPerformances([]);
        }
    }, [getPerformances, mediaType]);

    return (
        <AttributionGroupedBarChartCard
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="inherit">
                    <Printable show={true}>
                        <Typography variant="body1" color="text.primary">
                            Performance Metrics by {mediaType}
                        </Typography>
                    </Printable>
                    <Printable show={false}>
                        <>
                            <Typography variant="body1" color="text.primary">
                                Performance Metrics
                            </Typography>
                            {mediaTypes && mediaTypes.length > 0 && (
                                <FormControl sx={{ width: '150px', my: -1 }}>
                                    <TextField
                                        // label="Data Type"
                                        select
                                        required={true}
                                        size="small"
                                        SelectProps={{ native: false }}
                                        value={mediaType}
                                        onChange={(event) => {
                                            setMediaType(event.target.value);
                                        }}
                                    >
                                        {mediaTypes.map((_mediaType) => {
                                            return (
                                                <MenuItem key={_mediaType} value={_mediaType}>
                                                    {_mediaType}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            )}
                        </>
                    </Printable>
                </Row>
            }
            tooltip="An event as defined by advertiser, by Media Type."
            chartOptions={{
                legend: {
                    offsetY: 12,
                    position: 'top',
                },
                xaxis: {
                    categories: seriesDataLabels,
                },
            }}
            chartSeries={seriesData}
        />
    );
}
