import { ReactElement } from 'react';
import { Box, Button, Chip, List, ListItem, Tooltip, Typography } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import moment from 'moment-timezone';

import {
    AttributionStatusSet,
    PolkSalesWorkflow,
    PolkSalesWorkflowSet,
    PolkSalesWorkflowResult,
} from '../../types/Polk';
import { Dealer } from '../../types/Dealer';
import CDGrid from '../../components/CDGrid';
import Column from '../../components/Column';
import Utils from '../../components/Utils';
import ApiService from '../../ApiService';
import theme from '../../theme';

const renderSalesMonthCell = (salesWorkflow: PolkSalesWorkflow) => {
    return (
        <div>
            <Typography component="strong" variant="body2" sx={{ fontWeight: 'bold' }}>
                {Utils.formatDate(salesWorkflow.amcUpload.beginDate, 'MMMM YYYY')}
            </Typography>
            <div
                style={{
                    display: salesWorkflow.updateDate ? 'block' : 'none',
                }}
            >
                <Typography variant="body2" sx={{ fontSize: '0.675rem' }}>
                    Last Updated: {Utils.formatDateTime(salesWorkflow.updateDate)}
                </Typography>
            </div>
        </div>
    );
};

const renderAdvertisersCell = (salesWorkflow: PolkSalesWorkflow) => {
    return (
        <List sx={{ padding: 0 }}>
            {salesWorkflow.workflowResults.map((r: PolkSalesWorkflowResult) => {
                return (
                    <ListItem
                        key={r.id}
                        sx={{
                            padding: 0,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        <span>{r.sellingdealername}</span>
                    </ListItem>
                );
            })}
            <ListItem
                sx={{
                    fontWeight: 'bold',
                    padding: 0,
                    marginTop: 1,
                }}
            ></ListItem>
        </List>
    );
};

const renderStatusCell = (salesWorkflow: PolkSalesWorkflow) => {
    const statusElement: ReactElement = (
        <Chip
            label={Utils.convertCase(salesWorkflow.status)}
            variant="filled"
            color={
                salesWorkflow.status === 'SUCCEEDED'
                    ? 'success'
                    : salesWorkflow.status.indexOf('FAILED') > -1
                    ? 'error'
                    : 'warning'
            }
            sx={{ top: (theme) => theme.spacing(0.5) }}
        ></Chip>
    );

    return salesWorkflow.status === 'FAILED' ? (
        <Tooltip title={salesWorkflow.amcUpload.errorMessage}>{statusElement}</Tooltip>
    ) : (
        statusElement
    );
};

function downloadSalesData(amcUploadId: number) {
    ApiService.getPolkSalesData(amcUploadId)
        .then((response) => {
            Utils.downloadFile(response, 'text/csv');
        })
        .catch((error) => {
            console.log(error);
        });
}

const renderDataTypeCell = (salesWorkflow: PolkSalesWorkflow) => {
    return (
        <Button
            sx={{ top: (theme) => theme.spacing(-1) }}
            component="label"
            color="inherit"
            tabIndex={-1}
            endIcon={<FileDownloadOutlined />}
            onClick={() => downloadSalesData(salesWorkflow.amcUpload.id)}
        >
            {salesWorkflow.amcUpload.dataSet === 'polk_sales' ? 'Sales' : 'Opportunities'}
        </Button>
    );
};

const renderTotalSalesCell = (salesWorkflow: PolkSalesWorkflow) => {
    return (
        <List sx={{ padding: 0 }}>
            {salesWorkflow.workflowResults.map((r: PolkSalesWorkflowResult) => {
                return (
                    <ListItem key={r.id} sx={{ padding: 0 }}>
                        {r.salesCount}
                    </ListItem>
                );
            })}
            <ListItem
                sx={{
                    fontWeight: 'bold',
                    padding: 0,
                    marginTop: 1,
                }}
            >
                {salesWorkflow.workflowResults.reduce(
                    (accumulator: any, currentValue: any) => accumulator + currentValue.salesCount,
                    0
                )}
            </ListItem>
        </List>
    );
};

const renderTotalUsersCell = (salesWorkflow: PolkSalesWorkflow) => {
    return (
        <List sx={{ padding: 0 }}>
            {salesWorkflow.workflowResults.map((r: PolkSalesWorkflowResult) => {
                return (
                    <ListItem key={r.id} sx={{ padding: 0 }}>
                        {r.workflowExecutionStatus === 'SUCCEEDED'
                            ? r.distinctUserCount
                            : Utils.convertCase(r.workflowExecutionStatus)}
                    </ListItem>
                );
            })}
            <ListItem
                sx={{
                    fontWeight: 'bold',
                    padding: 0,
                    marginTop: 1,
                }}
            >
                {salesWorkflow.workflowResults.reduce(
                    (accumulator: any, currentValue: any) => accumulator + currentValue.distinctUserCount,
                    0
                )}
            </ListItem>
        </List>
    );
};

const calculateRowHeight = (rows: number): number => {
    const rowHeightPerPixel: number = 20;
    let rowHeight: number = 2 * rowHeightPerPixel;

    if (rows > 2) {
        rowHeight = rows * rowHeightPerPixel;
    }

    return rowHeight;
};

const renderCellColumn = (salesWorkflowSet: PolkSalesWorkflowSet, field: string) => {
    const salesWorkflow = salesWorkflowSet?.salesWorkflow;
    const opportunityWorkflow = salesWorkflowSet?.opportunityWorkflow;

    let salesElement: ReactElement = <></>;
    let salesElementHeight: number = calculateRowHeight(salesWorkflow?.workflowResults?.length ?? 0);
    let opportunityElement: ReactElement = <></>;
    let opportunityElementHeight: number = calculateRowHeight(opportunityWorkflow?.workflowResults?.length ?? 0);

    if (salesWorkflow) {
        switch (field) {
            case 'Advertiser':
                salesElement = renderAdvertisersCell(salesWorkflow);
                break;

            case 'Data Type':
                salesElement = renderDataTypeCell(salesWorkflow);
                break;

            case 'Sales Month':
                salesElement = renderSalesMonthCell(salesWorkflow);
                break;

            case 'Total Sales':
                salesElement = renderTotalSalesCell(salesWorkflow);
                break;

            case 'Total Users':
                salesElement = renderTotalUsersCell(salesWorkflow);
                break;

            case 'amcUpload.recordsIdentified':
                salesElement = <>{salesWorkflow?.amcUpload?.recordsIdentified}</>;
                break;

            case 'amcUpload.recordsSubmitted':
                salesElement = <>{salesWorkflow?.amcUpload?.recordsIdentified}</>;
                break;

            case 'status':
                salesElement = renderStatusCell(salesWorkflow);
                break;
        }
    }

    if (opportunityWorkflow) {
        switch (field) {
            case 'Advertiser':
                opportunityElement = renderAdvertisersCell(opportunityWorkflow);
                break;

            case 'Data Type':
                opportunityElement = renderDataTypeCell(opportunityWorkflow);
                break;

            case 'Sales Month':
                opportunityElement = <></>;
                break;

            case 'Total Sales':
                opportunityElement = renderTotalSalesCell(opportunityWorkflow);
                break;

            case 'Total Users':
                opportunityElement = renderTotalUsersCell(opportunityWorkflow);
                break;

            case 'amcUpload.recordsIdentified':
                opportunityElement = <>{opportunityWorkflow?.amcUpload?.recordsIdentified}</>;
                break;

            case 'amcUpload.recordsSubmitted':
                opportunityElement = <>{opportunityWorkflow?.amcUpload?.recordsIdentified}</>;
                break;

            case 'status':
                opportunityElement = renderStatusCell(opportunityWorkflow);
                break;
        }
    }

    return (
        <Column gap={3} width="inherit">
            <Box sx={{ height: salesElementHeight }}>{salesElement}</Box>
            <Box sx={{ height: opportunityElementHeight }}>{opportunityElement}</Box>
        </Column>
    );
};

export type PolkWorkflowSetDataGridProps = {
    dealer: Dealer;
    rows: PolkSalesWorkflowSet[];
};

export function PolkWorkflowSetDataGrid(props: PolkWorkflowSetDataGridProps) {
    const { dealer } = props;

    const columns: GridColumns = [
        {
            headerName: 'Sales Month',
            description: 'Polk sales month, and latest AMC Workflow run date',
            field: 'Sales Month',
            minWidth: 200,
            flex: 1,
            valueGetter: (params) => {
                const salesWorkflow = params.row?.salesWorkflow;
                return salesWorkflow?.updateDate;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row, 'Sales Month');
            },
        },
        {
            headerName: 'Status',
            description: 'AMC Workflow status',
            field: 'status',
            width: 50,
            flex: 0.5,
            renderCell: (params) => {
                return renderCellColumn(params.row, 'status');
            },
        },
        {
            headerName: 'Data Type',
            description: `Sales = ${dealer.dealerName} Polk sales. Opportunities = competitor’s Polk sales.`,
            field: 'Data Type',
            width: 50,
            flex: 0.5,
            valueGetter: (params) => {
                const salesWorkflow = params.row.salesWorkflow;
                return salesWorkflow?.amcUpload?.dataSet;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row, 'Data Type');
            },
        },
        {
            headerName: 'Submitted',
            description: 'Total Polk PII records Submitted',
            field: 'amcUpload.recordsSubmitted',
            width: 25,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row.salesWorkflow;
                return salesWorkflow?.amcUpload?.recordsSubmitted;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row, 'amcUpload.recordsSubmitted');
            },
        },
        {
            headerName: 'Identified',
            description: 'Total unique Amazon users identified from Polk PII records Submitted',
            field: 'amcUpload.recordsIdentified',
            width: 25,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return renderCellColumn(params.row, 'amcUpload.recordsIdentified');
            },
        },
        {
            headerName: 'Advertiser',
            description: 'Polk name(s) of Assigned Advertisers',
            field: 'Advertiser',
            minWidth: 300,
            flex: 1,
            renderCell: (params) => {
                return renderCellColumn(params.row, 'Advertiser');
            },
        },
        {
            headerName: 'Total Sales',
            description: 'Total Sales reported by Polk',
            field: 'Total Sales',
            width: 50,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.salesWorkflow;

                return (
                    salesWorkflow?.workflowResults?.reduce(
                        (accumulator: any, currentValue: any) => accumulator + currentValue.salesCount,
                        0
                    ) || 0
                );
            },
            renderCell: (params) => {
                return renderCellColumn(params.row, 'Total Sales');
            },
        },
        {
            headerName: 'Total Users',
            description: 'Total unique Amazon users who were exposed to an ad.',
            field: 'Total Users',
            width: 50,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.salesWorkflow;

                return (
                    salesWorkflow?.workflowResults?.reduce(
                        (accumulator: any, currentValue: any) => accumulator + currentValue.distinctUserCount,
                        0
                    ) || 0
                );
            },
            renderCell: (params) => {
                return renderCellColumn(params.row, 'Total Users');
            },
        },
    ];

    return (
        <CDGrid
            getRowId={(row) => {
                return row?.salesWorkflow?.id;
            }}
            autoPageSize={true}
            autoHeight={false}
            columns={columns}
            rows={props.rows}
            rowHeight={72}
            getRowHeight={() => 'auto'}
            PaperProps={{
                elevation: 3,
            }}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    px: 2,
                },
                '& .MuiDataGrid-cell': {
                    // backgroundColor: '#E9E9E9',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    paddingX: 2,
                    paddingTop: 2,
                    paddingBottom: 5,
                },
            }}
        />
    );
}

export type PolkAttributionStatusDataGridProps = {
    dealer: Dealer;
    setView: (attributionStatusSet: AttributionStatusSet) => void;
    // setRows: (rows: AttributionStatusSet[]) => void;
    rows: AttributionStatusSet[];
};

export default function PolkAttributionStatusDataGrid(props: PolkAttributionStatusDataGridProps) {
    const { dealer, setView } = props;

    // const PolkAttributionStatusDataGridRow = (props: GridRowProps) => {
    //     const { row } = props;
    //     return (
    //         <>
    //             <GridRow {...props} />

    //             {props?.row?.previousSalesWorkflowSets?.length > 0 && (
    //                 <>
    //                     <Box>
    //                         <Button
    //                             variant="text"
    //                             color="inherit"
    //                             size="medium"
    //                             startIcon={<OpenInNewIcon />}
    //                             onClick={() => {
    //                                 if (row) {
    //                                     setView(row as AttributionStatusSet);
    //                                 }
    //                             }}
    //                         >
    //                             View Previous Runs
    //                         </Button>
    //                     </Box>
    //                 </>
    //             )}
    //         </>
    //     );
    // };

    const columns: GridColumns = [
        {
            headerName: 'Sales Month',
            description: 'Polk sales month, and latest AMC Workflow run date',
            field: 'Sales Month',
            minWidth: 200,
            flex: 1,
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet?.salesWorkflow;
                return Utils.getDate(salesWorkflow?.amcUpload?.beginDate);
            },
            renderCell: (params) => {
                const salesWorkflow = params?.row?.currentSalesWorkflowSet?.salesWorkflow;
                const opportunityWorkflow = params?.row?.currentSalesWorkflowSet?.opportunityWorkflow;
                let salesElementHeight = calculateRowHeight(salesWorkflow?.workflowResults?.length || 0);
                let opportunityElementHeight: number = calculateRowHeight(
                    opportunityWorkflow?.workflowResults?.length || 0
                );

                return (
                    <Column gap={3} width="inherit">
                        <Box sx={{ height: salesElementHeight }}>
                            {(salesWorkflow && (
                                <div>
                                    <Typography component="strong" variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {Utils.formatDate(salesWorkflow.amcUpload.beginDate, 'MMMM YYYY')}
                                    </Typography>
                                    <div
                                        style={{
                                            display: salesWorkflow.updateDate ? 'block' : 'none',
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ fontSize: '0.675rem' }}>
                                            Last Updated: {Utils.formatDateTime(salesWorkflow.updateDate)}
                                        </Typography>
                                    </div>
                                    {params.row.previousSalesWorkflowSets.length > 0 && (
                                        <Box sx={{ mt: 1.5 }}>
                                            <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                onClick={() => {
                                                    if (params?.row) {
                                                        setView(params?.row as AttributionStatusSet);
                                                    }
                                                }}
                                                sx={{
                                                    left: '-5px',
                                                }}
                                            >
                                                View Previous Runs
                                            </Button>
                                        </Box>
                                    )}
                                </div>
                            )) || <></>}
                        </Box>
                        <Box sx={{ height: opportunityElementHeight }}></Box>
                    </Column>
                );
            },
        },
        {
            headerName: 'Status',
            description: 'AMC Workflow status',
            field: 'status',
            width: 50,
            flex: 0.5,
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'status');
            },
        },
        {
            headerName: 'Data Type',
            description: `Sales = ${dealer.dealerName} Polk sales. Opportunities = competitor’s Polk sales.`,
            field: 'Data Type',
            width: 50,
            flex: 0.5,
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet.salesWorkflow;
                return salesWorkflow?.amcUpload?.dataSet;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'Data Type');
            },
        },
        {
            headerName: 'Submitted',
            description: 'Total Polk PII records Submitted',
            field: 'amcUpload.recordsSubmitted',
            width: 25,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet.salesWorkflow;
                return salesWorkflow?.amcUpload?.recordsSubmitted;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'amcUpload.recordsSubmitted');
            },
        },
        {
            headerName: 'Identified',
            description: 'Total unique Amazon users identified from Polk PII records Submitted',
            field: 'amcUpload.recordsIdentified',
            width: 25,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet.salesWorkflow;
                return salesWorkflow?.amcUpload?.recordsIdentified;
            },
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'amcUpload.recordsIdentified');
            },
        },
        {
            headerName: 'Advertiser',
            description: 'Polk name(s) of Assigned Advertisers',
            field: 'Advertiser',
            minWidth: 300,
            flex: 0.5,
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'Advertiser');
            },
        },
        {
            headerName: 'Total Sales',
            description: 'Total Sales reported by Polk',
            field: 'Total Sales',
            width: 50,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet?.salesWorkflow;

                return (
                    salesWorkflow?.workflowResults?.reduce(
                        (accumulator: any, currentValue: any) => accumulator + currentValue.salesCount,
                        0
                    ) || 0
                );
            },
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'Total Sales');
            },
        },
        {
            headerName: 'Total Users',
            description: 'Total unique Amazon users who were exposed to an ad.',
            field: 'Total Users',
            width: 50,
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => {
                const salesWorkflow = params.row?.currentSalesWorkflowSet?.salesWorkflow;

                return (
                    salesWorkflow?.workflowResults?.reduce(
                        (accumulator: any, currentValue: any) => accumulator + currentValue.distinctUserCount,
                        0
                    ) || 0
                );
            },
            renderCell: (params) => {
                return renderCellColumn(params.row?.currentSalesWorkflowSet, 'Total Users');
            },
        },
    ];

    return (
        <CDGrid
            getRowId={(row) => {
                return moment(Utils.getDate(row?.beginDate)).format('YYYY-MM-DD');
            }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'Sales Month', sort: 'desc' }],
                },
            }}
            autoPageSize={true}
            autoHeight={false}
            columns={columns}
            rows={props.rows}
            rowHeight={72}
            getRowHeight={() => 'auto'}
            PaperProps={{
                elevation: 0,
            }}
            // components={{
            //     Row: PolkAttributionStatusDataGridRow,
            // }}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    px: 2,
                },
                '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    paddingX: 2,
                    paddingTop: 2,
                    paddingBottom: 5,
                },
            }}
        />
    );
}
