import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseInfo } from '@mui/x-license';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import AppRoutes from './AppRoutes';

if (process.env.REACT_APP_MUI_KEY) LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);
const routes = AppRoutes();

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider router={routes} />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
