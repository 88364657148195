import { useMemo } from 'react';
import { Card, CardContent, Stack, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    Moving as MovingIcon,
    TrendingDown as TrendingDownIcon,
} from '@mui/icons-material';
import theme from '../../theme';
import Printable from '../../components/Printable';
import Utils from '../../components/Utils';

export type AttributionMetric = {
    format: 'dollar' | 'decimal' | 'percent' | 'number';
    value: number;
    previousValue?: number;
    change?: number;
    changeLabel?: string | null;
};

export function calculateAttributionMetricChange(attributionMetric: AttributionMetric): number {
    let change: number = 0;

    if (attributionMetric.value !== undefined && attributionMetric.previousValue !== undefined) {
        if (attributionMetric.previousValue !== 0) {
            change = Utils.round((attributionMetric.value / attributionMetric.previousValue - 1) * 100, 2);
        }
    }

    return change;
}

export function formatAttributionMetricChangeLabel(attributionMetric: AttributionMetric): string {
    let changeLabel: string = '';
    let digits: number = 2;

    if (attributionMetric.change !== undefined) {
        if (attributionMetric.change > 0) {
            changeLabel = `${Utils.formatNumber(Utils.round(attributionMetric.change, digits), digits)}%`;
        } else {
            changeLabel = `${Utils.formatNumber(Utils.round(attributionMetric.change, digits), 0)}%`;
        }
    }

    return changeLabel;
}

export default function AttributionMetricCard(props: {
    metric?: AttributionMetric;

    label: string | null | undefined | React.ReactElement;
    sublabel?: string | null | undefined | React.ReactElement;
    value?: string | null | undefined | React.ReactElement;
    direction?: 'up' | 'down' | 'center';
    tooltip?: string;
    inverseColor?: boolean;
}) {
    let { metric, label, sublabel, value, direction, tooltip, inverseColor } = props;

    function formatValue(metric: AttributionMetric, value: any): any {
        switch (metric.format) {
            case 'dollar':
                return Utils.formatCurrency(value, 2);

            case 'decimal':
                return Utils.formatNumber(Utils.round(value, 2));

            case 'percent':
                return Utils.formatNumber(Utils.round(value, 2)) + ' %';

            default:
                return Utils.formatNumber(value);
        }
    }

    const metricColor = useMemo((): string => {
        if (metric && metric.change !== undefined) {
            if (inverseColor) {
                return metric.change > 0 ? theme.palette.error.main : theme.palette.success.main;
            }

            return metric.change > 0 ? theme.palette.success.main : theme.palette.error.main;
        }

        return '';
    }, [inverseColor, metric]);

    if (metric) {
        if (!sublabel) {
            sublabel = (
                <>
                    {metric.change !== undefined && (
                        <>
                            <span
                                style={{
                                    color: metricColor,
                                }}
                            >
                                {metric.changeLabel}
                            </span>

                            <span> vs. Previous Month</span>
                        </>
                    )}
                </>
            );
        }

        if (!value) {
            value = formatValue(metric, metric.value) as string;
        }

        if (!direction) {
            if (metric.change !== undefined) {
                if (metric.change > 0) {
                    direction = 'up';
                } else if (metric.change < 0) {
                    direction = 'down';
                } else {
                    direction = 'center';
                }
            }
        }
    }

    return (
        <Card variant="outlined">
            <CardContent
                sx={{
                    '&.MuiCardContent-root:last-child': {
                        pb: theme.spacing(2),
                    },
                }}
            >
                <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mb: 3 }}>
                    <Typography variant="body1" color="text.secondary">
                        {label}
                    </Typography>

                    <Printable show={false}>
                        {tooltip ? (
                            <Tooltip title={tooltip}>
                                <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </Printable>
                </Stack>

                <Typography
                    variant="h5"
                    component="div"
                    color="text.primary"
                    sx={{
                        fontSize: 24,
                        mb: 1,
                    }}
                >
                    {value}
                </Typography>

                <Stack direction="row" spacing={0.5}>
                    {sublabel ? (
                        <>
                            {direction === 'up' && <MovingIcon color={inverseColor ? 'error' : 'success'} />}
                            {direction === 'down' && <TrendingDownIcon color={inverseColor ? 'success' : 'error'} />}

                            <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                    fontSize: 14,
                                }}
                            >
                                {sublabel}
                            </Typography>
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
}
