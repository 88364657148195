import { useEffect, useMemo, useState } from 'react';
import { FormControl, MenuItem, TextField, Typography } from '@mui/material';

import { Performance } from '../../types/CampaignPerformance';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import AttributionBasicBarChartCard from './AttributionBasicBarChartCard';

export default function AdvertiserAverageCPMBasicBarChartCard() {
    const { getPerformances, getPerformanceMediaTypes } = useAttributionPageContext();

    // typeof MediaType = 'Display' | 'Video' | 'Audio';
    const mediaTypes: string[] = getPerformanceMediaTypes();
    const [mediaType, setMediaType] = useState<string>(() => {
        return Utils.localStorage('attribution.advertiserAverageCPMMediaType', 'Display');
    });
    const [performances, setPerformances] = useState<Performance[]>([]);

    const seriesData = useMemo(() => {
        if (performances && performances.length) {
            return performances
                .map((p: Performance) => {
                    return {
                        x: p.campaignName,
                        y: p.CPM,
                    };
                })
                .filter((s: any) => s.y !== null);
        }

        return [];
    }, [performances]);

    useEffect(() => {
        localStorage.setItem('attribution.advertiserAverageCPMMediaType', mediaType);
    }, [mediaType]);

    useEffect(() => {
        if (mediaTypes && mediaTypes.length) {
            if (!mediaTypes.includes(mediaType)) {
                setMediaType(mediaTypes[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaTypes]);

    useEffect(() => {
        if (mediaType) {
            setPerformances(getPerformances(mediaType));
        } else {
            setPerformances([]);
        }
    }, [getPerformances, mediaType]);

    return (
        <AttributionBasicBarChartCard
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="inherit">
                    <Printable show={true}>
                        <Typography variant="body1" color="text.primary">
                            Average CPM by {mediaType}
                        </Typography>
                    </Printable>
                    <Printable show={false}>
                        <>
                            <Typography variant="body1" color="text.primary">
                                Average CPM by
                            </Typography>
                            {mediaTypes && mediaTypes.length > 0 && (
                                <FormControl sx={{ width: '150px', my: -1 }}>
                                    <TextField
                                        // label="Media Type"
                                        select
                                        required={true}
                                        size="small"
                                        SelectProps={{ native: false }}
                                        value={mediaType}
                                        onChange={(event) => {
                                            setMediaType(event.target.value);
                                        }}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                    >
                                        {mediaTypes.map((_mediaType) => {
                                            return (
                                                <MenuItem key={_mediaType} value={_mediaType}>
                                                    {_mediaType}
                                                </MenuItem>
                                            );
                                        })}
                                    </TextField>
                                </FormControl>
                            )}
                        </>
                    </Printable>
                </Row>
            }
            tooltip="Cost per thousand impressions/exposures per ad type"
            chartOptions={{
                dataLabels: {
                    formatter: (value: number, opts) => {
                        if (typeof value === 'number') {
                            return Utils.formatCurrency(value, 2) as string;
                        }

                        return value;
                    },
                },
                xaxis: {
                    categories: seriesData.map((d) => d.x),
                },
            }}
            chartSeries={[
                {
                    name: 'Impressions',
                    data: seriesData.map((d) => d.y),
                },
            ]}
        />
    );
}
