import { useCallback, useState } from 'react';
import { AmcReachAndFrequency, AmcReachAndFrequencyCriteria } from '../types/AmcReachAndFrequency';
import ApiService from '../ApiService';

export interface AmcReachAndFrequencyListState {
    amcReachAndFrequencyList: AmcReachAndFrequency[];
    setAmcReachAndFrequencyList: (amcReachAndFrequencyList: AmcReachAndFrequency[]) => void;
    fetchAmcReachAndFrequencyList: (criteria: AmcReachAndFrequencyCriteria) => Promise<void>;
}

export function useAmcReachAndFrequencyList(): AmcReachAndFrequencyListState {
    const [amcReachAndFrequencyList, setAmcReachAndFrequencyList] = useState<AmcReachAndFrequency[]>([]);

    const fetchAmcReachAndFrequencyList = useCallback((criteria: AmcReachAndFrequencyCriteria) => {
        return ApiService.getAmcReachAndFrequencyList(criteria).then((response) => {
            setAmcReachAndFrequencyList(response.data);
        });
    }, []);

    return {
        amcReachAndFrequencyList,
        setAmcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList,
    };
}
