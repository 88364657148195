import { ChangeEvent, useEffect, useState } from 'react';
import { InputAdornment, MenuItem, TextField, TextFieldProps } from '@mui/material';
import { DateRangeOutlined } from '@mui/icons-material';
import theme from '../theme';
import moment from 'moment';

export interface CDMonth {
    key: string;
    value: string;
    label: string;
    date: Date;
}

export function generateMonths(lastNthMonths: Number = 6): CDMonth[] {
    const _months: CDMonth[] = [];
    const _currentMonth = moment().startOf('month').startOf('day');

    _months.push(
        ...[
            {
                key: moment(_currentMonth).format('YYYY-MM'),
                value: moment(_currentMonth).format('YYYY-MM'),
                label: moment(_currentMonth).format('MMMM YYYY'),
                date: moment(_currentMonth).toDate(),
            },
        ]
    );

    for (let nthMonth = 1; nthMonth <= lastNthMonths; nthMonth++) {
        const _nthMonth = moment(_currentMonth).subtract(nthMonth, 'months').startOf('month').startOf('day');

        _months.push({
            key: _nthMonth.format('YYYY-MM'),
            value: _nthMonth.format('YYYY-MM'),
            label: _nthMonth.format('MMMM YYYY'),
            date: _nthMonth.toDate(),
        });
    }

    return _months;
}

export function generateMonthsByDateRange(startDate: Date, endDate: Date | null = null): CDMonth[] {
    let lastNthMonths: number = 0;

    if (endDate) {
        lastNthMonths = moment(endDate).diff(moment(startDate), 'months');
    } else {
        lastNthMonths = moment().diff(moment(startDate), 'months');
    }

    console.log(generateMonths(lastNthMonths));

    return generateMonths(lastNthMonths);
}

export function formatMonth(value: any) {
    if (value instanceof Date) {
        return moment(value).format('YYYY-MM');
    }

    return value;
}

export type CDMonthSelectProps = TextFieldProps & {
    months: CDMonth[];
    change: Function;
};

export default function CDMonthSelect(props: CDMonthSelectProps) {
    const { change, months, value, ...textFieldProps } = props;
    const [month, setMonth] = useState<string>(value ? formatMonth(value) : moment().format('YYYY-MM'));

    useEffect(() => {
        if (value !== null) {
            setMonth(formatMonth(value));
        }
    }, [value]);

    useEffect(() => {
        const selectedMonth = months.find((_month: CDMonth) => _month.value === month);

        change(selectedMonth);
    }, [month]);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const selectedMonthId = event.target.value as string;
        const selectedMonth = months.find((_month: CDMonth) => _month.value === selectedMonthId);

        if (selectedMonth) {
            setMonth(selectedMonthId);
        }
    }

    return (
        <TextField
            required
            sx={{
                height: 40,
                width: 240,
                fontSize: theme.typography.fontSize,
                '& .MuiInputBase-input': {
                    color: theme.palette.text.secondary,
                    lineHeight: 1.65,
                    fontSize: theme.typography.fontSize,
                },
            }}
            label="Month"
            placeholder="Month"
            size="small"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <DateRangeOutlined fontSize="small" color="action" />
                    </InputAdornment>
                ),
            }}
            {...textFieldProps}
            value={month}
            select
            onChange={handleChange}
        >
            {months.map((_month) => {
                return (
                    <MenuItem key={_month.key} value={_month.value}>
                        {_month.label}
                    </MenuItem>
                );
            })}
        </TextField>
    );
}
