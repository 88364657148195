import { Performance } from '../types/CampaignPerformance';

export function calculatePerformanceValue(performance: Performance, type: string) {
    let value: number = 0;

    switch (type) {
        case 'eCPCV':
            if (performance.videoComplete > 0) {
                value = performance.totalCost / performance.videoComplete;
            }
            break;
    }

    return value;
}
