import moment from 'moment-timezone';

import { AttributionStatusSet, PolkSalesWorkflow, PolkSalesWorkflowSet } from '../types/Polk';
import Utils from '../components/Utils';

export function isOpportunitySalesWorkflow(salesWorkflow: PolkSalesWorkflow): boolean {
    return salesWorkflow?.amcUpload?.dataSet === 'polk_opportunities';
}

export function isSalesSalesWorkflow(salesWorkflow: PolkSalesWorkflow): boolean {
    return salesWorkflow?.amcUpload?.dataSet === 'polk_sales';
}

export function populatePolkSalesWorkflowSets(polkSalesWorkflows: PolkSalesWorkflow[]) {
    const polkSalesWorkflowSets: PolkSalesWorkflowSet[] = [];

    if (polkSalesWorkflows.length > 0) {
        let polkSalesWorkflowSet: PolkSalesWorkflowSet | null = null;

        for (let index = 0; index < polkSalesWorkflows.length; index++) {
            const polkSalesWorkflow: PolkSalesWorkflow = polkSalesWorkflows[index];

            let filled: boolean = false;
            let duplicate: boolean = true;

            if (!polkSalesWorkflowSet) {
                polkSalesWorkflowSet = {
                    beginDate: new Date(),
                    endDate: new Date(),
                    salesWorkflow: null,
                    opportunityWorkflow: null,
                };
            }

            if (polkSalesWorkflowSet.salesWorkflow === null && polkSalesWorkflowSet.opportunityWorkflow === null) {
                const beginDate: Date | null = Utils.getDate(polkSalesWorkflow.amcUpload.beginDate);
                const endDate: Date | null = Utils.getDate(polkSalesWorkflow.amcUpload.endDate);

                if (beginDate && endDate) {
                    polkSalesWorkflowSet = {
                        ...polkSalesWorkflowSet,
                        beginDate: beginDate,
                        endDate: endDate,
                    };
                }
            }

            switch (polkSalesWorkflow?.amcUpload?.dataSet) {
                case 'polk_sales':
                    if (polkSalesWorkflowSet.salesWorkflow === null) {
                        duplicate = false;
                        polkSalesWorkflowSet.salesWorkflow = polkSalesWorkflow;
                    }
                    break;

                case 'polk_opportunities':
                    if (polkSalesWorkflowSet.opportunityWorkflow === null) {
                        duplicate = false;
                        polkSalesWorkflowSet.opportunityWorkflow = polkSalesWorkflow;
                    }
                    break;
            }

            if (polkSalesWorkflowSet.salesWorkflow !== null && polkSalesWorkflowSet.opportunityWorkflow !== null) {
                filled = true;
            }

            if (filled === true || duplicate === true) {
                if (polkSalesWorkflowSet) {
                    polkSalesWorkflowSets.push({
                        ...polkSalesWorkflowSet,
                    });

                    polkSalesWorkflowSet = null;
                }
            }
        }
    }

    return polkSalesWorkflowSets;
}

export function popoluteAttributionStatusSets(polkSalesWorkflowSets: PolkSalesWorkflowSet[]): AttributionStatusSet[] {
    const attributionStatusSets: AttributionStatusSet[] = [];

    const findAttributionStatusSetIndex = (polkSalesWorkflowSet: PolkSalesWorkflowSet): number => {
        return attributionStatusSets.findIndex((attributionStatusSet) =>
            moment(attributionStatusSet.beginDate).isSame(polkSalesWorkflowSet.beginDate, 'day')
        );
    };

    if (polkSalesWorkflowSets.length > 0) {
        polkSalesWorkflowSets.forEach((polkSalesWorkflowSet) => {
            let initialized = false;
            let attributionStatusSetIndex: number = findAttributionStatusSetIndex(polkSalesWorkflowSet);

            if (attributionStatusSetIndex === -1) {
                let attributionStatusSet = {
                    beginDate: polkSalesWorkflowSet.beginDate,
                    endDate: polkSalesWorkflowSet.endDate,
                    currentSalesWorkflowSet: polkSalesWorkflowSet,
                    previousSalesWorkflowSets: [],
                };

                initialized = true;

                attributionStatusSets.push(attributionStatusSet);

                attributionStatusSetIndex = findAttributionStatusSetIndex(polkSalesWorkflowSet);
            }

            if (initialized === false) {
                attributionStatusSets[attributionStatusSetIndex].previousSalesWorkflowSets.push(polkSalesWorkflowSet);
            }
        });
    }

    return attributionStatusSets;
}
