import { Dealer } from './Dealer';
import { Agency } from './Agency';

export const OPS_ADMIN = 'OPS_ADMIN';

export const USER_GROUP_ADMIN = 'ADMIN';
export const USER_GROUP_ADVERTISER = 'DEALER';
export const USER_GROUP_AGENCY = 'AGENCY';

export const AGENCY_CAMPAIGNS = 'activeCampaigns';
export const AGENCY_REPORTING = 'activeReporting';
export const AGENCY_ATTRIBUTION = 'activeAttribution';
export const AGENCY_DATA = 'activeData';
export const AGENCY_API_DOCS = 'activeApiDocs';
export const AGENCY_ADVERTISERS = 'activeAdvertisers';
export const AGENCY_CRM_UPLOAD = 'activeCrmUpload';

export interface Permission {
    id: number;
    userType: string;
    permissionCode: string;
    description: string;
}

export class User {
    id?: number;
    userName: string = '';
    quickSightUserName: string | null = null;
    name: string = '';
    emailAddress: string = '';
    temporaryPassword?: string = '';
    enabled: boolean = true;
    status?: string = '';
    group?: string = 'DEALER';
    dealers: Dealer[] = [];
    agencyId: number | null = null;
    permissions: Permission[] = [];
    agency?: Agency;
    readOnly?: boolean = false;
    organization: string = '';
}
